import axios from 'axios';

const writeKey = process.env.VUE_APP_SEGMENT_WRITE_KEY;
const DEScript = process.env.VUE_APP_APOLLO_URL;

const getClientIp = async () => {
    let ip;
    await axios.get('https://api.ipify.org?format=json')
        .then((res) => {
            ip = res.data.ip;
        });
    return ip;
};

const leadingZero = (int) => {
    if (int < 10) {
        return `0${int}`;
    }
    return int.toString();
};

const getDateKey = () => {
    const now = new Date();
    const year = now.getFullYear();

    // js months are zero-based, add 1
    const month = leadingZero(now.getMonth() + 1);
    const date = leadingZero(now.getDate());
    const hour = leadingZero(now.getHours());

    return `${year}${month}${date}${hour}`;
};

const setDEAnalyticsConfig = async (instance) => {
    const ip = await getClientIp();

    const versionMap = {
        unauthenticated: 2,
        de: 1,
        partner: 5,
    };

    let version = versionMap.unauthenticated;

    const { isAuthenticated: partnerAuthenticated } = instance.$auth;

    if (partnerAuthenticated) {
        version = versionMap.partner;
    } else if (instance.$de.isAuthenticated) {
        version = versionMap.de;
    }

    const baseConfig = {
        writeKey,
        ip,
        version,
        regionTLD: '.com',
    };

    let authConfig = {};

    if (version !== versionMap.unauthenticated) {
        authConfig.id = instance.$store.getters.userId;
        authConfig.username = instance.$store.getters.userName;
    }

    if (version === versionMap.partner) {
        authConfig = {
            ...authConfig,
            ...{
                accountId: instance.$store.getters.organizationId,
                accountName: instance.$store.getters.getOrganization.name,
            },
        };
    } else if (version === versionMap.de) {
        const {
            account_name: accountName,
            account_guid: accountId,
            site_guid: siteId,
            site_name: siteName,
        } = instance.$store.getters.userData.site;

        const { impersonation = {} } = instance.$store.getters.userData;

        const {
            impersonated: isImpersonated = false,
            impersonator: impersonatorId = null,
        } = impersonation;

        authConfig = {
            ...authConfig,
            ...{
                accountName,
                accountId,
                siteId,
                siteName,
                isImpersonated,
                impersonatorId,
            },
        };
    }

    if (version !== version.unauthenticated) {
        return {
            ...baseConfig,
            ...authConfig,
        };
    }

    return baseConfig;
};

export default {
    DEScript,
    getDateKey,
    setDEAnalyticsConfig,
};
