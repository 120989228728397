const footerLinks = [
    {
        link: 'https://www.discoveryeducation.com/legal/terms/career-connect/',
        target: '_blank',
        routerLink: false,
        text: 'Terms of Use',
    },
    {
        link: 'https://www.discoveryeducation.com/legal/privacy/career-connect/',
        target: '_blank',
        routerLink: false,
        text: 'Privacy Policy',
    },
    {
        link: 'https://www.discoveryeducation.com/who-we-are/about-discovery-education.cfm',
        target: '_blank',
        routerLink: false,
        text: 'About Us',
    },
];

// eslint-disable-next-line import/prefer-default-export
export { footerLinks };
