function getCookieExpiration(days) {
    const date = new Date();

    // arithmetic here converts days to ms
    // after time addition, date is converted back to cookie-friendly format
    // and combined with the standard "expires" key
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    return `; expires=${date.toUTCString()};`;
}

// optionally pass an int for expiration. Default is usually end of session
function setCookie(key, value, daysUntilExpire, path = '/') {
    let expires = '';

    if (daysUntilExpire || daysUntilExpire === 0) {
        expires = getCookieExpiration(daysUntilExpire);
    }

    // `expires` will fill in the needed semicolon and space if `daysUntilExpire` is passed
    document.cookie = `${key}=${value}${expires} Path=${path};`;
}

function getCookie(key) {
    const cookies = document.cookie;
    const regex = new RegExp(`(\\s|^)${key}=((\\w|-|/)*)(;|$)`, 'g');
    const match = regex.exec(cookies);
    return match ? match[2] : '';
}

export {
    setCookie,
    getCookieExpiration,
    getCookie,
};
