<template>
    <transition name="nebula-modal-transition" mode="out-in">
        <NebulaModal
            v-if="showModal"
            class="bio-sample-modal"
            @close-modal="toggleModal"
            size="m"
        >
            <template v-slot:header>
                <h2>{{ $t('bio-sample-header', { ns: 'employeeprofile' }) }}</h2>
            </template>
            <template v-slot:content>
                <p>{{ $t('bio-sample-description', { ns: 'employeeprofile' }) }}</p>
                <div class="bio-sample-modal__sample-box">
                    <p class="bio-sample-modal__sample">{{ sampleText }}</p>
                    <div class="bio-sample-modal__button-container">
                        <NebulaButton
                            @click="copyToClipboard"
                            type="flat"
                            :icon-left="copyButton.icon"
                            size="s"
                            :text="copyButton.text"
                            :data-click-type="`Profile Bio Example Modal: Connector`"
                            :data-click-name="$t('bio-sample-copy-button', { ns: 'employeeprofile' })"
                        />
                    </div>
                </div>
            </template>
        </NebulaModal>
    </transition>
</template>

<script>
import { NebulaModal, NebulaButton } from '@discoveryedu/nebula-components';

export default {
    name: 'BioPlaceholderModal',
    components: {
        NebulaButton,
        NebulaModal,
    },
    data() {
        return {
            copyButton: {
                text: this.$t('bio-sample-copy-button', { ns: 'employeeprofile' }),
                icon: 'copy-to-clipboard',
            },
        };
    },
    computed: {
        showModal() {
            return this.$store.getters.profileBioModal.show;
        },
        sampleText() {
            return this.$t('bio-placeholder', { ns: 'employeeprofile' });
        },
    },
    methods: {
        toggleModal() {
            this.$store.dispatch('toggleModal', {
                category: 'profile',
                type: 'bio',
                show: !this.$store.getters.profileBioModal.show,
            });
        },
        copyToClipboard() {
            const blanksTrimmed = this.sampleText.replace(/(_)+/g, '___');
            navigator.clipboard.writeText(blanksTrimmed);
            this.copyButton.icon = 'circle-check--filled';
            this.copyButton.text = this.$t('bio-sample-copy-button--success', { ns: 'employeeprofile' });

            setTimeout(() => {
                this.copyButton.text = this.$t('bio-sample-copy-button', { ns: 'employeeprofile' });
                this.copyButton.icon = 'copy-to-clipboard';
            }, 3000);
        },
        boldPlaceholders() {
            const regex = /(\[(?:\w|\s|,|-)+\])/g;
            const sampleEl = document.querySelector('.bio-sample-modal__sample');
            if (!sampleEl) {
                return;
            }

            const string = sampleEl.textContent;
            const split = string.split(regex);
            sampleEl.textContent = '';
            split.forEach((str) => {
                const match = str.match(regex);
                let toAdd = str;
                if (match) {
                    const span = document.createElement('span');
                    span.classList.add('bio-sample-modal__sample-bold');
                    span.textContent = str;
                    toAdd = span;
                }

                sampleEl.append(toAdd);
            });
            // sampleEl.textContent = updated;
        },
    },
    watch: {
        showModal(updated) {
            if (updated) {
                this.$nextTick(() => {
                    this.boldPlaceholders();
                });
            }
        },
    },
};
</script>

<style lang="stylus">
.bio-sample-modal {
    // nebula overrides per design
    .nebula-modal__actions-container {
        display: none;
    }

    &__sample {
        nebula-text-body-2();
        margin: 0;
    }

    &__sample-bold {
        font-weight: 700;
    }

    &__sample-box {
        background-color: $nebula-color-platform-interactive-100;
        border-radius: $nebula-border-radius-small;
        padding: $nebula-space-3x $nebula-space-2x;
    }
    &__button-container {
        display: flex;
        justify-content: center;
        padding-block-start: $nebula-space-half;
    }
}
</style>
