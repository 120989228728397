import notifications from './notifications';
import requestDirectory from './requestDirectory';
import roles from './roles';
import browse from './browse';

const {
    ORGADMIN_ROLE,
    EMPLOYEE_ROLE,
    SCHOOLADMIN_ROLE,
    EDUCATOR_ROLE,
    DE_ROLE,
} = roles;

// metadata key from Auth0
export const DE_ADMIN = 'DE Admin';

export const ROLE_DISPLAY_MAP = {
    [EDUCATOR_ROLE]: 'Educator',
    [SCHOOLADMIN_ROLE]: 'School Admin',
    [EMPLOYEE_ROLE]: 'Connector',
    [DE_ROLE]: 'DE Admin',
    [ORGADMIN_ROLE]: 'Organization Admin',
};

// roles as currently stored in Auth0 metadata
export const METADATA_ROLES = {
    [ORGADMIN_ROLE]: 'Organization Admin',
};

export const MEMBER_ROLES = [EDUCATOR_ROLE, EMPLOYEE_ROLE];
export const ADMIN_ROLES = [ORGADMIN_ROLE, SCHOOLADMIN_ROLE, DE_ROLE];

export const partnerRoles = [ORGADMIN_ROLE, EMPLOYEE_ROLE, DE_ROLE];
export const educatorRoles = [EDUCATOR_ROLE, SCHOOLADMIN_ROLE];

export const partnerMap = {
    [EDUCATOR_ROLE]: [EMPLOYEE_ROLE],
    [EMPLOYEE_ROLE]: [EDUCATOR_ROLE],
    [ORGADMIN_ROLE]: [EMPLOYEE_ROLE, EDUCATOR_ROLE],
    [DE_ROLE]: [EMPLOYEE_ROLE, EDUCATOR_ROLE],
};

export const INVITE_MAX = 30;

// FORM VALUES
// stored here for consistency when used in multiple places

// request status values that display differently for different roles
export const draft = 'draft';
export const scheduled = 'scheduled';
export const completed = 'completed';
export const canceled = 'canceled';
export const actionRequired = 'action-required';
export const awaitingConfirmation = 'awaiting-confirmation';
export const pendingMatches = 'pending-matches';

export const expired = 'expired';

export const pastStatuses = [completed, expired, canceled];
export const futureStatuses = [draft, scheduled, actionRequired, awaitingConfirmation, pendingMatches];
export const allStatuses = [draft, expired, scheduled, completed, canceled, actionRequired, awaitingConfirmation, pendingMatches];
export const allHappyStatuses = [draft, scheduled, completed, actionRequired, awaitingConfirmation, pendingMatches];
export const allStatusesEmployee = [scheduled, completed, canceled, actionRequired, awaitingConfirmation, pendingMatches];
export const requestableStatuses = [actionRequired, pendingMatches];

export const employeeAcceptingApplications = [actionRequired, pendingMatches];
// statuses that can be considered "open for applications"
export const openStatuses = [awaitingConfirmation, pendingMatches, actionRequired];

// LOGIN MANAGEMENT
export const LOGIN_PREF_KEY = 'careers-app-login-pref';

export const baseAssetCategory = 'general';

export const rerouteInteractives = [
    '523f685e-457a-4336-8e1c-71fa34473999',
    'baacd50a-1ca0-4741-a2d7-f638b0012223',
    'da6f8208-616c-4c9b-a583-9c4d8091145f',
    'a9b9b540-1e71-4880-a2b2-0683d188cee0',
    '239d71cf-b7f0-4846-a9c1-b7c8a68f9f83',
    '32c832d6-09c6-4d25-ad1e-cf9e155c7ff8',
    '6e86baf8-6a2e-475b-8545-259f58fdb492',
];

export const envMap = {
    development: 'dev.',
    qa: 'qa.',
    staging: 'stage.',
    production: '',
};

export {
    notifications,
    requestDirectory,
    roles,
    browse,
};
