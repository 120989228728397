const ORGADMIN_ROLE = 'organization-admin';

// Connector and Employee are the same role, but 'employee' is used on the backend. Use `connector` anywhere user-facing
const EMPLOYEE_ROLE = 'employee';
const CONNECTOR_ROLE = 'connector';
const SCHOOLADMIN_ROLE = 'school-admin';
const EDUCATOR_ROLE = 'educator';
const DE_ROLE = 'de-admin';

export default {
    CONNECTOR_ROLE,
    ORGADMIN_ROLE,
    EMPLOYEE_ROLE,
    SCHOOLADMIN_ROLE,
    EDUCATOR_ROLE,
    DE_ROLE,
};
