import axios from 'axios';
import { stateOptions, typeOptions } from '@/data/formData';
import { formatDate } from '@/utils/formatDate';
import formatTime from '@/utils/formatTime';
import errors from '@/content/errors';
import { getLastNameInitials } from '@/utils/data/display';

// import { createURLParams } from '@/utils/fetches';
import {
    roles,
    educatorRoles,
    awaitingConfirmation,
    scheduled,
    canceled,
    actionRequired,
    pendingMatches,
} from '@/constants';

// import {
//     timestampNow,
// } from '@/data/placeholder/date';

const API_DOMAIN = process.env.VUE_APP_API_URL;

const resetPaginationAndRequest = async ({ dispatch }, payload) => {
    const { token, data } = payload;
    data.params.set('offset', 0);

    await dispatch('getRequests', { token, data });
};

const getDisplayName = (first, last, preferred) => {
    const displayFirst = preferred || first;
    return `${displayFirst} ${last}`;
};

const requestParse = (data) => {
    const {
        educatorFirstName,
        educatorLastName,
        educatorPreferredName,
        type,
    } = data;

    let schoolName;
    let location;
    let date;
    let time;
    let employeeName;
    let typeDisplay;
    const educatorName = getDisplayName(educatorFirstName, educatorLastName, educatorPreferredName);

    if (data.className) {
        const regex = /^(.+)(?:\|\|\|)(.+)$/;
        const match = data.className.match(regex);

        schoolName = match ? match[1].slice(0, 255) : null;
        location = match ? match[2].slice(0, 255) : null;

        if (location) {
            location = location.replace(/^null, /, '');
        }
    }

    if (data.startDateTime) {
        date = formatDate(data.startDateTime);
        time = formatTime(data.startDateTime * 1000);
    }

    if (data.employeeId) {
        const { employeeFirstName, employeeLastName, employeePreferredName } = data;
        employeeName = getDisplayName(employeeFirstName, getLastNameInitials(employeeLastName), employeePreferredName);
    }

    if (type) {
        const typeObj = typeOptions.find((each) => each.value === type);
        typeDisplay = typeObj ? typeObj.text : '';
    }

    return {
        ...data,
        ...{
            attachments: [],
            schoolName,
            location,
            date,
            time,
            employeeName,
            educatorName,
            typeDisplay,
        },
    };
};

const createRequest = async ({ commit, getters }, payload) => {
    const { id: userId, site: { site_name: siteName, site_city: siteCity, site_state: siteState } } = getters.userData;

    const stateDisplay = stateOptions.find((each) => each.fullname === siteState).value || siteState;
    const cityDisplay = siteCity ? `${siteCity}, ` : '';

    let endSlug = '';
    let changedData = {
        educatorId: userId,
        className: `${siteName}|||${cityDisplay}${stateDisplay}`,
    };

    if (payload.data.employeeId) {
        endSlug = `?employee_id=${payload.data.employeeId}`;
        changedData = {
            ...changedData,
            employeeId: null,
        };
    }

    try {
        const response = await axios.post(`${API_DOMAIN}/educators/${userId}/requests${endSlug}`,
            {
                ...payload.data,
                ...changedData,
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-Type': 'application/json',
                },
            });
        commit('UPDATE_PAGE', { request: requestParse(response.data) });
    } catch (error) {
        console.log(error);
        console.log(payload);
    }
};

const updateRequest = async ({ commit, state, dispatch }, payload) => {
    let roleSlug = '';
    let endSlug = '';

    const { data: { requestId }, token } = payload;
    const { category, ...rest } = payload.data;
    const { operation } = payload.data;
    let { key = 'request' } = payload.data;

    if (category) {
        // ex. 'applicants'
        endSlug = `/${category}`;
        key = category;
    }

    if (state.app.mode === roles.EDUCATOR_ROLE) {
        roleSlug = `/educators/${state.user.data.id}`;
    } else if (state.app.mode === roles.EMPLOYEE_ROLE) {
        roleSlug = `/employees/${state.user.data.employeeId}`;
    }

    try {
        const response = await axios.post(`${API_DOMAIN}${roleSlug}/requests/${requestId}${endSlug}`,
            rest,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
        commit('UPDATE_PAGE', { [key]: requestParse(response.data) });
        if (operation !== 'decline') {
            dispatch('getRequestAttachments', { token, data: { requestId } });
        }
    } catch (error) {
        commit('UPDATE_PAGE', { error: { description: 'We were unable to update this request. Please try again later.' } });
    }
};

const sortRequests = (requests, sort = 'startDate', order) => {
    if (!requests || requests.length < 2) {
        return requests;
    }
    // map the params (that need to be sent to the API as well) directly to request object keys
    // for stopgap FE sort
    // TODO: ideally this all happens on the backend, but sort also happens here when we are combining multiple API calls

    const alphaSort = (key) => requests.sort((a, b) => {
        const valueA = a[key].toUpperCase();
        const valueB = b[key].toUpperCase();

        if (order === 'DESC') {
            if (valueB < valueA) {
                return -1;
            }
            if (valueB > valueA) {
                return 1;
            }
        }

        if (valueA < valueB) {
            return -1;
        }
        if (valueA > valueB) {
            return 1;
        }

        return 0;
    });

    const intSort = (key) => {
        if (order === 'DESC') {
            return requests.sort((a, b) => b[key] - a[key]);
        }

        return requests.sort((a, b) => a[key] - b[key]);
    };

    const sortMap = {
        startDate: {
            key: 'startDateTime',
            sortFunc: intSort,
        },
        subject: {
            key: 'primarySubject',
            sortFunc: alphaSort,
        },
        type: {
            key: 'type',
            sortFunc: alphaSort,
        },
    };

    const { sortFunc } = sortMap[sort];
    const sortKey = sortMap[sort].key;
    const sorted = sortFunc(sortKey);
    return sorted.filter((item, idx) => {
        const firstIndex = sorted.findIndex((each) => each.requestId === item.requestId);
        return idx === firstIndex;
    });
};

const getMessageRequests = async ({ commit }, payload) => {
    const response = await axios.get(`${API_DOMAIN}/requests/messages`,
        {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-Type': 'application/json',
            },
        });
    const clean = response.data.filter((each) => each.employeeId);

    commit('UPDATE_PAGE', { [payload.data.key]: clean });
};

const getRequests = async ({ commit, state, dispatch }, payload) => {
    commit('UPDATE_LOADING', { key: 'requests', status: true });

    const {
        params = '',
        requestId,
        category,
        excludeUnassigned = [],
    } = payload.data;

    const isEmployee = state.app.mode === roles.EMPLOYEE_ROLE;
    const batchFetch = !requestId;

    let { key = 'requests' } = payload.data;

    let roleSlug = '';
    let requestIdSlug = '';
    let paramsSlug = '';
    let endSlug = '';
    let sort;
    let order;
    let offset;
    let hasEmployeeStatus;

    const storedCategories = ['applications'];

    if (state.app.mode === roles.EDUCATOR_ROLE) {
        roleSlug = `/educators/${state.user.data.id}`;
    } else if (state.app.mode === roles.EMPLOYEE_ROLE) {
        roleSlug = batchFetch ? `/employees/${state.user.data.employeeId}` : '';
    }

    if (requestId) {
        requestIdSlug = `/${requestId}`;
        key = 'request';
    }

    if (typeof params === 'object') {
        // exclude open requests, except for admins
        if (state.app.mode !== roles.DE_ROLE) {
            params.set('include_direct_requests', false);
        }

        // pull these params out to use internally in this function
        sort = params.get('sort');
        order = params.get('order');
        offset = params.get('offset');

        hasEmployeeStatus = Boolean(params.get('employee_status'));

        paramsSlug = `?${params.toString()}`;
    } else if (typeof params === 'string' && params.length > 0) {
        paramsSlug = `?${params}`;

        const employeeStatusRegex = /(?:\?|&)employee_status=(?:[a-z]+)(?:$|&)/;
        hasEmployeeStatus = Boolean(paramsSlug.match(employeeStatusRegex));
    }

    if (category) {
        // ex. 'applicants'
        endSlug = `/${category}`;

        // if this is a category whose results will be accessed separately from requests
        if (storedCategories.includes(category)) {
            key = category;
        }
    }

    try {
        const response = await axios.get(`${API_DOMAIN}${roleSlug}/requests${requestIdSlug}${endSlug}${paramsSlug}`,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-Type': 'application/json',
                },
            });

        let { data } = response;

        if (batchFetch) {
            data = response.data.records || [];
            if (data.length < 1 && (offset && offset > 0)) {
                // if the length of the results does not make sense with the pagination params, reset to first page and re-fetch
                dispatch('resetPaginationAndRequest', payload);
                return;
            }

            if (!category) {
                dispatch('updatePagination', response.data);
            }
        }

        // TODO: remove this when no longer needed
        // when we have added open requests to the employee endpoint
        if (isEmployee && batchFetch && !category && !hasEmployeeStatus) {
            const unassigned = await dispatch('getUnassignedRequests', payload);
            const unassignedTypes = Object.keys(unassigned);

            unassignedTypes.forEach((type) => {
                if (!excludeUnassigned.includes(type)) {
                    data = data.concat(unassigned[type]);
                }
            });
            data = sortRequests(data, sort, order);
        }

        if (!batchFetch) {
            // initialize attachments on a single request fetch
            data = requestParse(data);
        } else {
            data = data.map((each) => requestParse(each));
        }

        commit('UPDATE_LOADING', { key: 'requests', status: false });
        commit('UPDATE_PAGE', { [key]: data });
        commit('UPDATE_ERROR', null);
    } catch (error) {
        errors.populatePageError(error?.response?.status, commit);
        commit('UPDATE_LOADING', { key: 'requests', status: false });
    }
};

const removeDeclined = (requestList, applications) => requestList.filter((request) => {
    const application = applications.find((each) => each.requestId === request.requestId);
    if (!application) {
        return true;
    }
    return !application.declined;
});

const getUnassignedRequests = async ({ state }, payload) => {
    const { params = '', includeDirectRequests } = payload.data;

    let paramsSlug = '';
    let sort;
    let order;

    if (typeof params === 'object') {
        sort = params.get('sort');
        order = params.get('order');

        paramsSlug = `?${params.toString()}`;
    } else if (typeof params === 'string' && params.length > 0) {
        paramsSlug = `?${params}`;
    }

    let openRequests = [];
    let directRequests = [];
    let pendingRequests = [];
    const response = await axios.get(`${API_DOMAIN}/requests${paramsSlug}`,
        {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-Type': 'application/json',
            },
        });

    const { records } = response.data;
    if (records) {
        openRequests = records.filter((request) => {
            const visibleStatuses = [pendingMatches, actionRequired];
            const correctStatus = visibleStatuses.includes(request.status);
            const noEmployee = !request.employeeId;

            const application = state.page.applications.find((each) => each.requestId === request.requestId);

            return correctStatus && noEmployee && !application;
        });

        pendingRequests = records.filter((request) => {
            const visibleStatuses = [actionRequired];
            const correctStatus = visibleStatuses.includes(request.status);
            const noEmployee = !request.employeeId;

            const application = state.page.applications.find((each) => each.requestId === request.requestId);

            return correctStatus && noEmployee && application;
        });

        directRequests = records.filter((request) => {
            const visibleStatuses = [awaitingConfirmation];
            const correctStatus = visibleStatuses.includes(request.status);
            const application = state.page.applications.find((each) => each.requestId === request.requestId);

            if (!application) {
                return false;
            }

            return correctStatus && application.directRequest;
        });
    }

    if (state.page.applications.length > 0) {
        // filter from the current data any requests employee has been declined from
        openRequests = removeDeclined(openRequests, state.page.applications);
        pendingRequests = removeDeclined(pendingRequests, state.page.applications);
        if (includeDirectRequests) {
            directRequests = removeDeclined(directRequests, state.page.applications);
        }
    }

    if (sort && order) {
        openRequests = sortRequests(openRequests, sort, order);
        pendingRequests = sortRequests(pendingRequests, sort, order);

        if (directRequests && directRequests.length > 0) {
            directRequests = sortRequests(directRequests, sort, order);
        }
    }

    // const toReturn = includeDirectRequests ? openRequests.concat(directRequests) : openRequests;

    return { open: openRequests, direct: directRequests, pending: pendingRequests };
};

const getApplicants = async ({ commit, state }, payload) => {
    const { requestId, requestStatus } = payload.data;
    let roleSlug = '';

    if (educatorRoles.includes(state.app.mode)) {
        roleSlug = `/educators/${state.user.data.id}`;
    } else if (state.app.mode === roles.EMPLOYEE_ROLE) {
        roleSlug = `/employees/${state.user.data.employeeId}`;
    }

    try {
        const response = await axios.get(`${API_DOMAIN}${roleSlug}/requests/${requestId}/applicants`,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-Type': 'application/json',
                },
            });

        let results;
        let directRequestedConnector;

        if (response.data.records) {
            results = response.data.records.filter((applicant) => !applicant.declined && !applicant.directRequest);

            // there may be lingering "direct request" applicants, so only pull this out if the request is pending an accept
            if (requestStatus === awaitingConfirmation) {
                directRequestedConnector = response.data.records.find((applicant) => applicant.directRequest);
            }
        } else {
            results = response.data.records;
        }

        if (results) {
            commit('UPDATE_PAGE', { applicants: results });
        }
        if (directRequestedConnector) {
            commit('UPDATE_PAGE', { direct_requested_applicant: directRequestedConnector });
        }
    } catch (error) {
        console.log(error);
        console.log(payload);
    }
};

const getConnectorMatches = async ({ commit, state }, payload) => {
    const { requestId } = payload.data;

    try {
        const response = await axios.get(`${API_DOMAIN}/educators/${state.user.data.id}/requests/${requestId}/matches`,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-Type': 'application/json',
                },
            });
        if (response.data.records) {
            commit('UPDATE_PAGE', { request_matches: response.data.records });
        }
    } catch (error) {
        console.log(error);
        console.log(payload);
    }
};

const getConnectorMatchesTemp = async ({ commit }, payload) => {
    try {
        const response = await axios.get(`${API_DOMAIN}/employees?limit=4&sort=matches`,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-Type': 'application/json',
                },
            });

        if (response.data.records) {
            commit('UPDATE_PAGE', { request_matches: response.data.records });
        }
    } catch (error) {
        console.log(error);
        console.log(payload);
    }
};

// For educators only
const acceptApplicant = async ({ dispatch }, payload) => {
    const { token, data } = payload;
    const { requestId } = data;

    const category = 'applicants';
    const operation = 'accept';

    const acceptPayload = { token, data: { ...{ category, operation }, ...data } };
    const fetchPayload = { token, data: { requestId } };

    await dispatch('updateRequest', acceptPayload);
    await dispatch('getRequests', fetchPayload);
};

const declineApplicant = async ({ dispatch }, payload) => {
    const { token, data } = payload;
    const { requestId } = data;

    const category = 'applicants';
    const operation = 'decline';

    const declinePayload = { token, data: { ...{ category, operation }, ...data } };
    const fetchPayload = { token, data: { requestId } };

    await dispatch('updateRequest', declinePayload);
    await dispatch('getRequests', fetchPayload);
};

const cancelRequest = async ({ dispatch, state }, payload) => {
    const { token, data } = payload;
    if (state.app.mode === roles.EMPLOYEE_ROLE) {
        const operation = 'cancel';
        const cancelPayload = { token, data: { ...{ operation }, ...data } };
        await dispatch('updateRequest', cancelPayload);
    } else if (state.app.mode === roles.EDUCATOR_ROLE) {
        // only educators can cancel a request altogether. Employees can only remove themselves by cancelling
        const status = canceled;
        const statusPayload = { token, data: { ...{ status }, ...data } };
        await dispatch('updateRequest', statusPayload);
    }
};

// employee user fetch applications
const getApplications = async ({ dispatch }, payload) => {
    const { token } = payload;

    const category = 'applications';

    const fetchPayload = { token, data: { category, params: 'limit=100' } };

    await dispatch('getRequests', fetchPayload);
};

const submitDirectRequest = async ({ dispatch }, payload) => {
    const category = 'applicants';
    const operation = 'direct';
    const { token } = payload;

    const updatePayload = { token, data: { category, operation, ...payload.data } };

    await dispatch('updateRequest', updatePayload);
};

const confirmDirectRequest = async ({ dispatch, getters }, payload) => {
    const operation = 'confirm';
    const { token } = payload;
    const updatePayload = { token, data: { operation, ...payload.data } };

    await dispatch('updateRequest', updatePayload);
    if (getters.request.status === scheduled) {
        await dispatch('updateResults', { key: 'request_direct_accept', status: 'success' });
    }
};

const declineDirectRequest = async ({ dispatch }, payload) => {
    const operation = 'decline';
    const { token } = payload;
    const updatePayload = { token, data: { operation, ...payload.data } };

    await dispatch('updateRequest', updatePayload);
};

export default {
    getApplicants,
    getConnectorMatches,
    getUnassignedRequests,
    createRequest,
    updateRequest,
    getRequests,
    getConnectorMatchesTemp,
    acceptApplicant,
    cancelRequest,
    declineApplicant,
    getApplications,
    submitDirectRequest,
    confirmDirectRequest,
    declineDirectRequest,
    resetPaginationAndRequest,
    getMessageRequests,
};
