import { typeOptions } from '@/data/formData';

/**
 * Get the initials of the last name.
 * @param {string} lastName - The last name to get the initials of.
 * @returns {string} The initials of the last name.
 * @example
 * getLastNameInitials('Smith-Jones') // 'S.J.'
 * getLastNameInitials('García López') // 'G.L.'
 */
const getLastNameInitials = (lastName) => {
    if (!lastName) return '';

    return lastName
        .trim()
        .split(/[\s-]+/)
        .map((part) => `${part.charAt(0).toUpperCase()}.`)
        .join('');
};

const displayName = (data, isEducator = true) => {
    const { preferredName, firstName, lastName } = data;
    return `${preferredName || firstName} ${isEducator ? lastName : getLastNameInitials(lastName)}`;
};

const requestTypeDisplay = (type) => {
    if (!type) {
        return '';
    }

    const typeObj = typeOptions.find((each) => each.value === type);
    return typeObj.text;
};

export {
    displayName,
    requestTypeDisplay,
    getLastNameInitials,
};
