import deAuth from '@/auth/deAuth';
import store from '@/store';
import getToken from '@/utils/getToken';
import isSupported from '@/utils/routes/isSupported';

import {
    DE_ADMIN,
    roles,
} from '@/constants';

import envFlags from '@/data/flags/env_flags.json';
import educatorFlags from '@/data/flags/flag_map.json';
import employeeFlags from '@/data/flags/employee_flag_map.json';
import { getCookie, setCookie } from '@/utils/cookies';

import { getAuth0Instance } from './index';

function errorRouting(to, next) {
    const hasError = !!store.state.app.error;

    if (!hasError && to.name !== 'error') {
        return;
    }

    // if there's an error and the user is routing elsewhere, route to the error page
    if (hasError && to.name !== 'error') {
        next('/error');
    }

    // if someone is routing the error page without an error
    // route them back home
    if (to.name === 'error' && !hasError) {
        next('/');
    }
}

async function checkAuthentication(auth0AuthService, deAuthInstance) {
    // const appMode = store.state.app.mode;

    // if this user is a role managed by Auth0, check Auth0
    if (auth0AuthService.isAuthenticated) {
        return true;
    }

    const deAuthenticated = await deAuthInstance.isAuthenticated;
    return deAuthenticated;
}

function flagRouting(to, next) {
    // block certain routes to flags
    const flagMap = {
        // key is route name, value is flag name
        // this is just reversed to keep flag names consistent
        message: 'messaging',
        settings: 'settings',
    };

    const flaggedRoutes = Object.keys(flagMap);

    if (!flaggedRoutes.includes(to.name)) {
        // bail if route has no associated flag
        return;
    }

    const { flags } = store.state.app;

    const isEnabled = flags[flagMap[to.name]];
    if (!isEnabled) {
        // route home if user does not have access to this route
        next('/');
    }
}

// eslint-disable-next-line import/prefer-default-export, consistent-return
export const authGuard = async (to, from, next, authService, deAuthInstance) => {
    const publicRoutes = ['login', 'oauth2-authorized'];
    const deAdminRoutes = ['org-management', 'educator-management', 'request-admin', 'request-details', 'test-asset', 'feature-flags'];
    const educatorRoutes = [
        'browse-connectors',
        'browse-organizations',
        'new-request',
        'new-direct-request',
        'request-edit',
        'browse-all',
        'browse-content',
        'direct-request',
        'spotlight-content',
    ];
    const allowedAllUsers = [
        'profile-setup',
        'organization-profile',
        'dashboard',
        'connector-profile',
        'browse-org-connectors',
        'asset-details',
        'organization-content',
        'organization-category-content',
        'connect-directory',
        'request-details',
        'error',
        'message',
        'settings',
    ];
    const orgAdminRoutes = ['user-management'];

    const authenticated = await checkAuthentication(authService, deAuthInstance);

    const deAdminMode = authenticated && store.state.app.mode === roles.DE_ROLE;
    const orgAdminMode = authenticated && store.state.app.mode === roles.ORGADMIN_ROLE;
    const educatorMode = authenticated && store.state.app.mode === roles.EDUCATOR_ROLE;

    const deepLinkKey = 'cc-route';

    // eslint-disable-next-line consistent-return
    const fn = async () => {
        const deepLink = getCookie(deepLinkKey);
        if (authenticated && deepLink && !deepLink.includes('login')) {
            if (orgAdminMode) {
                setCookie('careers-app-mode', roles.EMPLOYEE_ROLE);
                await store.dispatch('updateMode', roles.EMPLOYEE_ROLE);
            }

            setCookie(deepLinkKey, deepLink, 0);

            const deepLinkSupported = isSupported(deepLink);
            if (to.path !== deepLink && deepLinkSupported) {
                return next(deepLink);
            }
        }

        errorRouting(to, next);
        flagRouting(to, next);

        if (!authenticated && publicRoutes.includes(to.name)) {
            return next();
        }

        if (authenticated && publicRoutes.includes(to.name)) {
            return next('/');
        }

        if (deAdminMode) {
            if (deAdminRoutes.includes(to.name)) {
                return next();
            }
            return next('/org-management');
        }

        // If the user is authenticated and the route is allowed for anyone authenticated
        // continue with the route
        if (authenticated && allowedAllUsers.includes(to.name)) {
            return next();
        }

        if (orgAdminMode && orgAdminRoutes.includes(to.name)) {
            return next();
        }

        if (educatorMode && educatorRoutes.includes(to.name)) {
            return next();
        }

        if (authenticated) {
            return next('/');
        }

        const excludedDeepLinks = ['dashboard', 'login', 'error'];
        // store attempted route in local storage before routing to login
        if (to.name && !excludedDeepLinks.includes(to.name)) {
            setCookie(deepLinkKey, to.path, 0.5);
        }
        return next({ name: 'login' });
    };

    return fn();
};

export const routeGuide = async (to, from, next) => {
    store.dispatch('updateLoading', { key: 'app', status: true });
    // some breadcrumbs will be added on mount or should not be tracked. Exclude them here
    const excludeBreadcrumb = [
        'browse-org-connectors',
        'organization-category-content',
        'organization-profile',
        'new-request',
        'edit-request',
        'spotlight-content',
        'oauth2-authorized',
        'login',
        'profile-setup',
    ];
    if (!excludeBreadcrumb.includes(to.name)) {
        // enables dynamic breadcrumbs
        store.dispatch('updateBreadcrumb', {
            link: to.path,
            text: to.name,
        });
    }

    const authService = getAuth0Instance();
    const deAuthInstance = deAuth.getInstance();

    await new Promise((resolve) => {
        // if auth has already loaded, resolve
        if (!authService.loading) {
            resolve();
        }
        // otherwise, wait for it to finish loading
        authService.$watch('loading', (loading) => {
            if (loading === false) {
                resolve();
            }
        });
    });

    const userDatafromAuth = await new Promise((resolve) => {
        if (!authService.user) {
            resolve();
        }

        // fetch user metadata from auth0
        const { user_metadata: userMetadata } = authService.user;

        const IS_DE = userMetadata[DE_ADMIN];

        if (IS_DE) {
            store.dispatch('updateMode', roles.DE_ROLE);
            resolve();
        }

        // eslint-disable-next-line camelcase
        const { employee_id, organizations, feature_flags: featureFlags } = userMetadata;

        const env = process.env.NODE_ENV;
        const flaggedEnv = Object.keys(envFlags).includes(env);

        if (flaggedEnv) {
            const flagMap = envFlags[env];
            store.dispatch('updateFeatureFlags', { flags: flagMap });
        }

        // eslint-disable-next-line camelcase
        if (!employee_id && !IS_DE) {
            authService.logout();
        }
        // pull feature flags in from metadata and set in app state
        if (featureFlags) {
            store.dispatch('updateFeatureFlags', { flags: featureFlags });
        }

        // eslint-disable-next-line camelcase
        if (employee_id && organizations) {
            // TODO remove this filter if the metadata is cleaned up, this was from an early bug with user removal/invites
            const orgIds = Object.keys(organizations).filter((id) => id.toLowerCase() !== 'none');

            const parsedOrganizations = [];
            orgIds.forEach((each) => {
                parsedOrganizations.push({
                    organizationId: each,
                    roles: organizations[each],
                });
            });

            resolve({ employee_id, organizations, parsedOrganizations });
        }
    });

    // if authenticated via DE
    if (deAuthInstance.isAuthenticated) {
        const token = await deAuthInstance.getTokenSilently();

        if (deAuthInstance.error) {
            store.dispatch('updateError', {
                title: 'Authentication error',
                message: deAuthInstance.error,
            });
        }

        let { id } = store.state.user.data;

        if (!id && !deAuthInstance.error) {
            // Get DE user data first to check roles
            await store.dispatch('getDEUser', { token });

            // Check if user is a student from the response
            const { data: { roles: userRoles } } = store.state.user;

            const isStudent = userRoles && userRoles.some((role) => role.code === 'STUDENT');

            if (isStudent) {
                store.dispatch('updateMode', roles.STUDENT_ROLE);
            } else {
                store.dispatch('updateMode', roles.EDUCATOR_ROLE);
            }

            if (!store.state.app.error) {
                id = store.state.user.data.id;
                const { id: siteId } = store.state.user.data.site;

                await store.dispatch('getEducator', { token, data: { educatorId: id } });
                await store.dispatch('getSite', { token, siteId });
            }

            const env = process.env.NODE_ENV;
            const flaggedEducatorEnv = Object.keys(educatorFlags).includes(env);
            if (flaggedEducatorEnv && Object.keys(educatorFlags[env]).includes(id)) {
                const flagMap = educatorFlags[env][id];
                store.dispatch('updateFeatureFlags', { flags: flagMap });
            }

            const flaggedEnv = Object.keys(envFlags).includes(env);
            if (flaggedEnv) {
                const flagMap = envFlags[env];
                store.dispatch('updateFeatureFlags', { flags: flagMap });
            }
        }

        const { flags } = store.state.app;

        if (store.state.user.data && !store.state.app.error) {
            const { isActive } = store.state.user.data;
            store.dispatch('updateApp', { active: isActive });

            if (isActive && to.name === 'profile-setup' && flags['educator-setup-redesign']) {
                // active educator cannot route to profile setup confirmation
                return next('/');
            }
        }
    }

    // if Auth0 partner user is authenticated and not a DE admin
    if (authService.isAuthenticated && store.state.app.mode !== roles.DE_ROLE) {
        const token = await getToken(authService);
        const userData = await userDatafromAuth;
        const { employeeId } = store.state.user.data;

        if (!employeeId) {
            await store.dispatch('updateUser', { employeeId: userData.employee_id, organizations: userData.parsedOrganizations });
            await store.dispatch('getEmployee', { token });
        }

        if (userData.organizations && !store.state.app.error) {
            // check organizations metadata for an admin role
            const adminOrg = Object.keys(userData.organizations).find((orgId) => {
                const orgRoles = userData.organizations[orgId].map((each) => each.replace(/\s+/g, '-').toLowerCase());
                return orgRoles.includes(roles.ORGADMIN_ROLE);
            });

            const memberOrg = Object.keys(userData.organizations)[0];

            // const { organizationId } = store.state.app.organization.organizationId;
            // let primaryOrg;

            // if admin org found, update app mode and fetch organization data
            if (adminOrg) {
                let localAppMode = getCookie('careers-app-mode');

                await store.dispatch('getOrganization', {
                    token,
                    organizationId: adminOrg,
                });

                if (!store.state.app.organization.isActive) {
                    // don't allow employee mode for an admin of an inactive organization
                    if (localAppMode === roles.EMPLOYEE_ROLE) {
                        // deactivate the cookie for cleanup if this situation happens
                        setCookie('careers-app-mode', roles.ORGADMIN_ROLE, 0);
                        localAppMode = roles.ORGADMIN_ROLE;
                    }

                    store.dispatch('updateMode', roles.ORGADMIN_ROLE);
                    store.dispatch('updateApp', { active: false });
                }

                store.dispatch('updateUser', { hasAdmin: true });

                // check local storage for a set app mode pass to vuex if found
                if (localAppMode === roles.EMPLOYEE_ROLE) {
                    const personalProfileActive = store.state.user.data.isActive;
                    if (!personalProfileActive && to.name !== 'profile-setup') {
                        // route admin users who are in employee mode to profile setup
                        // don't use app mode here, to retain navigation
                        next({ name: 'profile-setup' });
                    }
                    store.dispatch('updateMode', roles.EMPLOYEE_ROLE);
                } else {
                    // otherwise, set default app mode
                    store.dispatch('updateMode', roles.ORGADMIN_ROLE);
                }
            } else {
                setCookie('careers-app-mode', roles.EMPLOYEE_ROLE);
                store.dispatch('updateMode', roles.EMPLOYEE_ROLE);

                // if user is not admin, fetch the org data using the first organization listed
                await store.dispatch('getOrganization', {
                    token,
                    organizationId: memberOrg,
                });

                if (store.state.user && !store.state.user.data.isActive) {
                    store.dispatch('updateApp', { active: false });
                }
            }
        }
        const env = process.env.NODE_ENV;
        const flaggedEmployeeEnv = Object.keys(employeeFlags).includes(env);
        if (flaggedEmployeeEnv && Object.keys(employeeFlags[env]).includes(userData.employee_id)) {
            const flagMap = employeeFlags[env][userData.employee_id];
            store.dispatch('updateFeatureFlags', { flags: flagMap });
        }
    }

    // if not a DE admin,
    // user is not activated,
    // not already routing to profile setup, and not routing to an error page
    // route user to profile setup. They need to set up and activate/confirm their profile before using the app
    if (store.state.app.mode !== roles.DE_ROLE && !store.state.app.active && to.name !== 'profile-setup' && !store.state.app.error) {
        next({ name: 'profile-setup' });
    }
    store.dispatch('updateLoading', { key: 'app', status: false });

    return authGuard(to, from, next, authService, deAuthInstance);
};
