import roles from './roles';

const type = {
    header: 'Type',
    field: 'type',
};

const date = {
    header: 'Date',
    sortable: true,
    field: 'date',
};

const primarySubject = {
    header: 'Subject Area',
    field: 'primarySubject',
};

const time = {
    header: 'Time',
    popover: 'Times are automatically updated to reflect your time zone',
    field: 'time',
};

const status = {
    header: 'Status',
    field: 'status',
    details: ['requestId', 'startDateTime'],
};

const educator = {
    header: 'Educator',
    field: 'educator',
    details: ['educatorAvatarUrl', 'educatorName'],
};

const connector = {
    header: 'Connector',
    field: 'connector',
    details: ['employeeAvatarUrl', 'employeeName', 'employee_deleted'],
};

const schoolName = {
    header: 'School',
    field: 'schoolName',
    details: ['location', 'schoolName'],
};

const title = {
    header: 'Title',
    field: 'title',
};

const gradeLevel = {
    header: 'Grade Level',
    field: 'gradeLevel',
};

const organization = {
    header: 'Organization',
    field: 'organization',
    details: ['organization'],
};

// centralizes which columns are available for what users on the request directory table
const columns = {
    [roles.ORGADMIN_ROLE]: {
        dashboard: [
            connector,
            {
                header: 'Type',
                field: 'type',
                showRecommended: false,
            },
            date,
            primarySubject,
            schoolName,
            status,
        ],
        self: [
            connector,
            {
                header: 'Type',
                field: 'type',
                showRecommended: false,
            },
            date,
            primarySubject,
            schoolName,
            status,
        ],
        open: [
            {
                header: 'Type',
                field: 'type',
                showRecommended: false,
            },
            title,
            date,
            time,
            primarySubject,
            gradeLevel,
        ],
    },
    [roles.EMPLOYEE_ROLE]: {
        dashboard: [
            {
                header: 'Connect Type',
                field: 'type',
                showRecommended: true,
                details: ['recommended'],
            },
            date,
            time,
            primarySubject,
            schoolName,
            educator,
            status,
        ],
        open: [
            {
                header: 'Type',
                field: 'type',
            },
            title,
            date,
            time,
            primarySubject,
            gradeLevel,
        ],
        self: [
            {
                header: 'Type',
                field: 'type',
                showRecommended: true,
                details: ['recommended'],
            },
            date,
            time,
            primarySubject,
            schoolName,
            educator,
            status,
        ],
    },
    [roles.EDUCATOR_ROLE]: {
        dashboard: [
            {
                header: 'Type',
                field: 'type',
            },
            date,
            time,
            primarySubject,
            connector,
            organization,
            status,
        ],
        directory: [
            type,
            date,
            time,
            primarySubject,
            connector,
            organization,
            status,
        ],
    },
    [roles.DE_ROLE]: {
        directory: [
            title,
            date,
            primarySubject,
            connector,
            educator,
            organization,
            status,
        ],
    },
};

export default columns;
