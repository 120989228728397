const getRegexList = () => {
    const uuidRegex = '([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})';
    const subcategory = '((\\w|-|\\S)+)';

    const organizationProfile = new RegExp(`^/organization/${uuidRegex}$`);
    const organizationContent = new RegExp(`^/organization/${uuidRegex}/content$`);
    const organizationCategoryContent = new RegExp(`^/organization/${uuidRegex}/${subcategory}/content$`);
    const connectorProfile = new RegExp(`^/connector/${uuidRegex}$`);
    const assetDetails = new RegExp(`^/asset/${uuidRegex}$`);
    const browseOrgConnectors = new RegExp(`^/${uuidRegex}/connectors$`);
    const requestDetails = new RegExp(`^/request/${uuidRegex}$`);
    const requestEdit = new RegExp(`^/request/${uuidRegex}/edit$`);
    const directRequest = new RegExp(`^/direct-request/${uuidRegex}$`);
    const spotlightContent = new RegExp(`^/content/${subcategory}$`);

    return [
        /^\/$/,
        /^\/profile-setup$/,
        /^\/user-management$/,
        /^\/connectors$/,
        /^\/organizations$/,
        /^\/request\/new$/,
        /^\/connect-directory$/,
        /^\/settings$/,
        /^\/browse$/,
        /^\/content$/,
        /^\/message$/,
        requestDetails,
        organizationProfile,
        organizationContent,
        organizationCategoryContent,
        connectorProfile,
        assetDetails,
        browseOrgConnectors,
        requestEdit,
        directRequest,
        spotlightContent,
        /^\/org-management$/,
        /^\/educator-management$/,
        /^\/request-admin$/,
        /^\/test-asset$/,
        /^\/feature-flags$/,
    ];
};

const isSupported = (path) => {
    if (!path) {
        return false;
    }
    const supported = getRegexList();
    const cleanPath = path.toLowerCase().trim();
    const matched = supported.find((regex) => {
        const match = cleanPath.match(regex);
        return !!match;
    });

    return !!matched;
};

export default isSupported;
