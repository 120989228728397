const TOGGLE_MODAL = (state, data) => {
    const {
        category,
        type,
        show,
        ...rest
    } = data;

    state.modals[category][type].show = show;

    Object.keys(rest).forEach((key) => {
        state.modals[category][type][key] = data[key];
    });
};

export default { TOGGLE_MODAL };
