<template>
    <div>
        <BioPlaceholderModal />
        <AvatarUploadModal />
    </div>
</template>

<script>
import BioPlaceholderModal from '@/components/modals/ProfileSetup/BioPlaceholder.vue';
import AvatarUploadModal from '@/components/modals/AvatarUploadModal.vue';

export default {
    name: 'ProfileSetupModals',
    components: {
        BioPlaceholderModal,
        AvatarUploadModal,
    },
};
</script>
