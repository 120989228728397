const modals = {
    user_management: {
        remove: {
            email: '',
            show: false,
            employeeId: '',
        },
    },
    profile: {
        bio: {
            show: false,
        },
    },
    request: {
        apply: {
            requestId: '',
            educatorName: '',
            show: false,
        },
        reschedule: {
            show: false,
        },
        edit: {
            show: false,
        },
        cancel: {
            show: false,
            requestStatus: '',
        },
        approve: {
            show: false,
        },
        decline: {
            show: false,
        },
        'decline-direct': {
            show: false,
            requestId: '',
        },
    },
    settings: {
        upload_image: {
            show: false,
        },
    },
};

export default modals;
