<template>
    <div id="app">
        <div v-if="onEducatorHomepage" class="app-banner">
            <a :href="bannerLink" class="app-banner__link">
                Explore Collecting and Analyzing Data Resources
                <NebulaIcon symbol-id="caret-right" class="app-banner__icon" />
            </a>
        </div>

        <div id="nebula-popover-trigger__popover-accounts">
            <NebulaProductBar
                :prelogin="hideAvatar"
                class="product-bar"
                :homeLogoElement="homeLogoElementValue"
                :homeLink="homeLinkValue"
                searchAction=""
                @open-global-nav="handleToggleGlobalNav"
                @open-profile-panel="openProfilePopover"
                :globalNavLabel="$t('menu')"
                pageTitleLogo="career-connect"
                :profileButtonLabel="$t('view-profile', { ns: 'profile' })"
                :userInitials="initials"
                :avatarImage="avatarImage"
                :hideGlobalNav="isBlockedRole"
            >
                <template v-slot:actionButton v-if="showNotifications">
                    <NotificationsPopover />
                </template>
            </NebulaProductBar>
            <AccountPopover
                ref="accountPopover"
                :member="userData"
                :organization="organization"
                :loading="loading"
                id="popover-accounts"
            />
        </div>
        <div class="product-container">
            <GlobalNav
                v-if="!isBlockedRole"
                homeLink="/"
                :data="globalNavData"
                :isOpen="globalNavOpen"
                ref="globalNav"
                @toggle-global-nav="handleToggleGlobalNav"
            />
            <div
                v-if="globalNavOpen && !isBlockedRole"
                class="careers-global-nav__overlay"
                @click="handleToggleGlobalNav"
            />
            <div class="product-content">
                <GrayBoxAppLayout v-if="loading" />
                <router-view />
            </div>
        </div>
        <NebulaFooter
            v-if="!$auth.loading"
            :user-is-student="false"
            class="careers-footer"
            :footerLinks="footerLinks"
            :backToTop="true"
        />
        <UserManagementModals />
        <ProfileSetupModals />
        <RequestModals />
    </div>
</template>

<script>
import {
    NebulaFooter, NebulaIcon, NebulaProductBar,
} from '@discoveryedu/nebula-components';
import AccountPopover from '@/components/shared/AccountPopover.vue';
import UserManagementModals from '@/components/modals/UserManagementModals.vue';
import ProfileSetupModals from '@/components/modals/ProfileSetupModals.vue';
import RequestModals from '@/components/modals/RequestModals.vue';

import GrayBoxAppLayout from '@/components/shared/Loader/GrayBoxAppLayout.vue';

import GlobalNav from '@/components/shared/GlobalNav.vue';
import analytics from '@/analytics';

import {
    roles,
    educatorRoles,
    MEMBER_ROLES,
    BLOCKED_ROLES,
    envMap,
} from '@/constants';
import { footerLinks } from '@/data/footerLinks';
import {
    spotlights,
    getOrgId,
} from '@/utils/data/resources';
import createPayload from '@/mixins/data/createPayload';
import NotificationsPopover from '@/components/shared/NotificationsPopover.vue';

export default {
    name: 'App',
    components: {
        AccountPopover,
        NotificationsPopover,
        NebulaFooter,
        NebulaProductBar,
        GlobalNav,
        UserManagementModals,
        ProfileSetupModals,
        RequestModals,
        GrayBoxAppLayout,
        NebulaIcon,
    },
    mixins: [createPayload],
    data() {
        return {
            profilePanelCollapsed: true,
            globalNavOpen: false,
            globalNavData: [],
            footerLinks,
        };
    },
    computed: {
        authenticated() {
            return this.$auth.isAuthenticated || this.$de.isAuthenticated;
        },
        hideAvatar() {
            return !this.authenticated || this.loading || this.setupMode;
        },
        showNotifications() {
            if (!this.authenticated || !MEMBER_ROLES.includes(this.appMode)) {
                return false;
            }

            return !this.hideAvatar;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        setupMode() {
            return !this.$store.state.app.active;
        },
        getSidebarEl() {
            return document.getElementsByClassName('nebula-sidebar')[0];
        },
        userData() {
            return this.$store.state.user.data;
        },
        organization() {
            return this.$store.state.app.organization;
        },
        loading() {
            return this.$store.state.status.app.loading;
        },
        initials() {
            const { organization, userData } = this;
            if (this.appMode === 'organization-admin') {
                return organization.name.charAt(0);
            }
            const firstInitialPreferred = userData.preferredName && userData.preferredName.charAt(0);
            const firstInitial = userData.firstName && userData.firstName.charAt(0);
            const lastInitial = userData.lastName && userData.lastName.charAt(0);

            if (!firstInitial || !lastInitial) {
                return '';
            }
            const compiledInitals = `${firstInitialPreferred || firstInitial}${lastInitial}`;
            return compiledInitals;
        },
        avatarImage() {
            const { organization, userData } = this;
            if (this.appMode === 'organization-admin') {
                return organization.avatarUrl;
            }
            return userData.avatarUrl;
        },
        // feature flags
        flags() {
            return this.$store.getters.flags;
        },
        helpAndSupportLink() {
            if (educatorRoles.includes(this.appMode)) {
                return this.$t('help-link-educators', { ns: 'help' });
            }

            return this.$t('help-link-employees', { ns: 'help' });
        },
        onEducatorHomepage() {
            return this.$route.name === 'dashboard' && this.appMode === roles.EDUCATOR_ROLE;
        },
        bannerLink() {
            const org = getOrgId('lyda-hill');
            return `/organization/${org}`;
        },
        isBlockedRole() {
            return BLOCKED_ROLES.includes(this.appMode);
        },
        isEducator() {
            return this.appMode === roles.EDUCATOR_ROLE;
        },
        isDevQaOrStage() {
            const env = process.env.NODE_ENV;
            return ['development', 'qa', 'staging'].includes(env);
        },
        homeLogoElementValue() {
            return this.isEducator && this.isDevQaOrStage ? 'a' : 'RouterLink';
        },
        homeLinkValue() {
            if (this.isEducator && this.isDevQaOrStage) {
                const env = process.env.NODE_ENV;
                const envPrefix = (envMap[env.toLowerCase()] || '');
                return `https://app.${envPrefix}discoveryeducation.com/`;
            }
            return '/';
        },
    },
    methods: {
        openProfilePopover() {
            setTimeout(this.startPopoverTimeOut, 300);
        },
        startPopoverTimeOut() {
            this.$refs.accountPopover.triggerAccountPopover();
            this.$nextTick(() => {
                window.dispatchEvent(new Event('resize'));
            });
        },
        injectLogo() {
            const oldLogo = document.querySelector('.nebula-product-bar__page-title-logo');

            if (!oldLogo) {
                return;
            }

            const CCLogo = document.createElement('img');
            CCLogo.setAttribute('src', '/img/career_connect_logo.svg');
            CCLogo.setAttribute('alt', 'career connect logo');
            CCLogo.classList.add('nebula-product-bar__page-title-logo');
            oldLogo.replaceWith(CCLogo);
        },
        replaceFooterText() {
            // The interpolation setting on localization seems to be incompatible with
            // how this app applies localization.
            // TODO resolve this, but for now update this way
            const lineEl = document.querySelector('.nebula-footer__bottom>p');

            if (!lineEl) {
                return;
            }

            const text = lineEl.textContent;
            const name = 'Discovery Education';

            if (text.includes(name)) {
                // bail if footer text already set
                return;
            }

            const now = new Date();
            const year = now.getFullYear();

            const replace = text.replace(/%\(name\)s/g, name).replace(/%\(year\)s/g, year);

            lineEl.textContent = replace;
        },
        handleToggleGlobalNav() {
            if (!this.$data.globalNavOpen) {
                this.$refs.globalNav.focusOnOpen();
            }

            this.$data.globalNavOpen = !this.$data.globalNavOpen;
        },
        makeSegmentScript(dateKey) {
            // Add analytics
            const analyticsScript = document.createElement('script');
            analyticsScript.onload = async () => {
                window.DEAnalyticsInitData = await analytics.setDEAnalyticsConfig(this);
                if (window.DEAnalytics) {
                    window.DEAnalytics.init();
                }
            };
            analyticsScript.setAttribute('src', `${analytics.DEScript}?key=${dateKey}`);
            document.head.appendChild(analyticsScript);
        },
        implementSegment() {
            if (this.appMode === roles.DE_ROLE) {
                return;
            }
            const dateKey = analytics.getDateKey();
            // check for existing script
            const script = document.querySelector(`head>script[src^='${analytics.DEScript}']`);

            // If analytics script not detected
            if (!script) {
                // Add analytics
                this.makeSegmentScript(dateKey);
                return;
            }

            const currentSrc = script.getAttribute('src');
            const regex = new RegExp('key=(\\d*)$', 'g');
            const currentDateKey = regex.exec(currentSrc)[1];

            if (currentDateKey === dateKey) {
                // bail if script, with correct key is detected
                // no action needed
                return;
            }

            // if script is detected with outdated key, replace
            script.setAttribute('src', `${analytics.DEScript}?key=${dateKey}`);
        },
        populateGlobalNav() {
            const links = [];
            if (this.appMode !== roles.DE_ROLE) {
                links.push(
                    {
                        display: 'Dashboard',
                        icon: 'dashboard',
                        path: '/',
                    },
                );
            }

            if (this.appMode !== roles.DE_ROLE) {
                links.push(
                    {
                        display: 'Connect Directory',
                        icon: 'clipboard',
                        path: '/connect-directory',
                    },
                );
            }

            if (this.appMode !== roles.DE_ROLE && this.authenticated && MEMBER_ROLES.includes(this.appMode) && this.flags.messaging) {
                links.push(
                    {
                        display: 'Messages',
                        icon: 'mail',
                        path: '/message',
                    },
                );
            }

            if (this.appMode === roles.EDUCATOR_ROLE) {
                links.push(
                    {
                        display: 'Browse All',
                        icon: 'explore',
                        path: '/browse',
                    },
                );
            }

            if (this.appMode === roles.DE_ROLE) {
                links.push(
                    {
                        display: 'Educator Management',
                        icon: 'explore',
                        path: '/educator-management',
                    },
                    {
                        display: 'Organization Management',
                        icon: 'explore',
                        path: '/org-management',
                    },
                    {
                        display: 'Requests Admin',
                        icon: 'explore',
                        path: '/request-admin',
                    },
                    {
                        display: 'Test Atlas Asset',
                        icon: 'explore',
                        path: '/test-asset',
                    },
                    {
                        display: 'Feature Flags',
                        icon: 'explore',
                        path: '/feature-flags',
                    },
                );
            }

            const supportLinks = [
            ];

            if (this.flags.settings && MEMBER_ROLES.includes(this.appMode)) {
                supportLinks.push(
                    {
                        display: 'Settings and Preferences',
                        icon: 'gear',
                        path: '/settings',
                    },
                );
            }

            supportLinks.push(
                {
                    display: this.$t('help-link-text', { ns: 'help' }),
                    icon: 'help',
                    path: this.helpAndSupportLink,
                },
            );

            this.globalNavData = [
                {
                    name: 'My Career App',
                    links,
                },
                {
                    name: 'Support',
                    links: supportLinks,
                },
            ];
        },
        async pollNotifications() {
            const payload = await this.createPayload();
            this.$store.dispatch('getNotifications', payload);
        },
    },
    async mounted() {
        this.injectLogo();
        await this.$nextTick;
        this.injectLogo();

        this.populateGlobalNav();
        this.replaceFooterText();

        this.footerLinks.push(
            {
                link: this.helpAndSupportLink,
                target: '_blank',
                routerLink: false,
                text: this.$t('help-link-text', { ns: 'help' }),
            },
        );

        window.addEventListener('popstate', () => {
            this.$store.dispatch('resetBreadcrumbs');
        });
    },
    watch: {
        async loading(loading) {
            if (!loading) {
                this.implementSegment();

                await this.$nextTick;
                this.injectLogo();
                this.replaceFooterText();

                this.populateGlobalNav();

                if (this.showNotifications) {
                    this.pollNotifications();
                    this.notificationPolling = setInterval(() => {
                        this.pollNotifications();
                    }, 1000 * 60 * 5);
                }

                const now = new Date();
                const nowTime = now.getTime();
                const env = process.env.NODE_ENV;

                // Use this for scheduling spotlight releases
                // Set release dates here for each env as needed
                let spotlightReleaseDate;
                if (env.toLowerCase() === 'qa') {
                    now.setMonth(9);
                    now.setDate(3);
                    now.setFullYear(2024);
                    spotlightReleaseDate = now.getTime();
                } else {
                    // in production
                    now.setMonth(9);
                    now.setDate(11);
                    now.setFullYear(2024);
                    spotlightReleaseDate = now.getTime();
                }

                // this is the check for the current time being past the release date
                // this uses a feature flag that is activated by a date-based reference
                // set feature flag to true if past release date
                if (nowTime >= spotlightReleaseDate) {
                    await this.$store.dispatch('updateFeatureFlags',
                        { flags: { ...this.$store.getters.flags, ...{ 'digital-citizenship-spotlight': true } } });
                } else {
                    await this.$store.dispatch('updateFeatureFlags',
                        { flags: { ...this.$store.getters.flags, ...{ 'digital-citizenship-spotlight': false } } });
                }

                // publish the spotlight to the global store if the flag is enabled
                if (this.flags['digital-citizenship-spotlight']) {
                    this.$store.dispatch('updateApp', { spotlight: spotlights['digital-citizenship'] });
                } else {
                    // this should be the spotlight (or lack of) BEFORE release
                    this.$store.dispatch('updateApp', { spotlight: spotlights['workforce-development'] });
                }
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.notificationPolling);
        this.notificationPolling = null;
    },
};
</script>

<style lang='stylus'>

:root {
    --nebula-input-max-width: 100%;
    --nebula-select-max-width: 100%;
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
}

#app {
    font-family: var(--nebula-font-family-display);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $nebula-color-platform-neutral-900;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    header-base();
}

.app-banner {
    background-color: #003d6b;
    font-weight: 700;
    height: 56px;
    margin: auto;
    padding: 8px;
    width: 100%;

    &__icon {
        color: #fff;
        fill: #fff;
        font-weight: 700;
        height: 12px;
    }

    &__link {
        align-items: center;
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        text-align: center;
        text-decoration: none;
    }

    &:hover {
        background-color: #0069B9;
    }
}

.careers-footer {
    z-index: 1;
}

.nebula-sidebar__section .nebula-button.router-link-exact-active {
    background: var(--nebula-button-background-hover);
    border-color: var(--nebula-button-border-color-hover);
    color: var(--nebula-button-text-color-hover);
}

.nebula-sidebar__toggle-nav-button {
    display: none;
}

.nebula-legend {
    display: block;
    font-size: $nebula-font-size-body-2;
    font-weight: 500;
    margin-bottom: $nebula-space-1x;
}

.nebula-fieldset {
    border-width: 0;
    margin-bottom: $nebula-space-3x;
    padding: 0;
    width: 100%;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.nebula-select .nebula-select__input {
    background-color: $nebula-color-platform-white;
    border-radius: $nebula-border-radius-small;
}

.nebula-product-bar__toggle-search-button {
    display: none;
}

.product {
    &-bar {
        z-index: 1;
        position: relative;
    }
}

.product-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #FDFDFD;
}

.product-content {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.careers-global-nav__overlay {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}

@import '~@discoveryedu/nebula-core/dist/styles/nebula_core.css';
@import '~@discoveryedu/nebula-core/dist/styles/fonts/nebula_fonts.css';
@import '~@discoveryedu/nebula-components/dist/nebula-components.css';
</style>
