import axios from 'axios';
import { ATLAS_APP_BASE_URL } from '@/constants';

const API_DOMAIN = process.env.VUE_APP_API_URL;

const getDEUser = async ({ commit, dispatch }, data) => {
    const accessToken = data.token;
    let response;

    try {
        response = await axios.get(`${API_DOMAIN}/deproxy/v1/users/me?return_avatar=true`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        commit('UPDATE_ERROR', null);
    } catch {
        commit('UPDATE_ERROR', 'Oops, something went wrong. Please try again later.');
        dispatch('updateApp', { active: true });
        return null;
    }

    const {
        id,
        first_name: firstName,
        last_name: lastName,
        site,
        email,
        products,
        timezone,
        avatar: avatarUrl,
        roles,
    } = response.data.user;

    const hasAccess = !!products.find((product) => product.code === 'CARCON');
    const isStudent = roles && roles.some((role) => role.code === 'STUDENT');

    if (isStudent) {
        commit('UPDATE_ERROR', 'Oops, something went wrong. Please try again later.');
        dispatch('updateApp', { active: true });
    }

    if (!hasAccess) {
        commit('UPDATE_ERROR', {
            title: 'Looks like you\'re trying to access Career Connect',
            message: 'You are not yet provisioned to use Career Connect. Click below to learn more about how to get access.',
            image: 'zoeConcerned',
            primaryButton: {
                text: 'Learn More',
                link: `${ATLAS_APP_BASE_URL}/`,
            },
        });
        dispatch('updateApp', { active: true });
    }

    return commit('UPDATE_USER_DATA', {
        id,
        firstName,
        lastName,
        site,
        email,
        timezone,
        avatarUrl,
        roles,
    });
};

const getSite = async ({ commit }, payload) => {
    const { siteId, token } = payload;
    let site;
    try {
        const response = await axios.get(`${API_DOMAIN}/deproxy/v1/sites/${siteId}/location`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        site = response.data.site;
    } catch (e) {
        console.log(e);
    }

    if (site) {
        return commit('UPDATE_USER_DATA', {
            site,
        });
    }
    return null;
};

export default { getDEUser, getSite };
